import React from "react"
import { objectToArray, toPlainText } from "./plainTextHelpers"

const ReadTime = (props) => {
  const value = objectToArray(props)
  const wordsPerMinute = 200
  const plainText = toPlainText(value)
  const wordTokens = plainText.split(/\w+/g).filter(Boolean)
  const wordCount = wordTokens.length
  const readingTime = Math.ceil(wordCount / wordsPerMinute)

  return (
    <b>{`${readingTime}${readingTime > 1 ? 'mins' : 'min'} `}</b>
  )
}

export default ReadTime
