import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import LatestBlocksSection from "../../components/page-builder/latestBlocksSection"
import Sidebar from "../../components/sidebar"
import Categories from "../../components/categories"
import PostCTA from "../../components/postCta"
import BlockContent from "../../components/page-builder/richText"
import ReadTime from "../../lib/readTime"
import styles from "./blog.module.scss"

export const query = graphql`
  query($slug: String) {
    sanityBlog(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      title
      category {
        slug {
          current
        }
        title
      }
      publishedAt(formatString: "MMM D, YYYY")
      author {
        name
        bio
        image {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      _rawCta(resolveReferences: { maxDepth: 2 })
      _rawCtaRef(resolveReferences: { maxDepth: 2 })
      cta {
        ctaImage {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      ctaRef {
        cta {
          ctaImage {
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
      _rawBody
    }
    allBlogCats: allSanityBlog {
      distinct(field: category___slug___current)
    }
  }
`

const BlogTemplate = props => {
  const { data, location } = props
  const post = data && data.sanityBlog
  const title = post.seo && post.seo.metaTitle ? post.seo.metaTitle : post.title
  const description =
    post.seo && post.seo.description ? post.seo.description : null
  const ogImage =
    post.seo &&
    post.seo.openGraphImage &&
    post.seo.openGraphImage.asset !== null
      ? post.seo.openGraphImage.asset.url
      : null
  let cta = post._rawCta
  let image = post.cta
  if (cta === null || cta.ctaHeader === undefined) {
    cta = post._rawCtaRef ? post._rawCtaRef.cta : null
    image = post.ctaRef ? post.ctaRef.cta : null
  }

  return (
    <>
      <Layout>
        <Helmet>
          {post.seo && post.seo.noIndex && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <SEO title={title} description={description} image={ogImage} />
        <Breadcrumbs location={location}>
          <Link to="/">Home</Link>
          <Link to="/blog/">Blog</Link>
          <Link to={`/blog/${post.category.slug.current}/`}>
            {post.category.title}
          </Link>
          <p>{post.title}</p>
        </Breadcrumbs>
        <section className={styles.blogHeader}>
          <div className={styles.blogHeaderContent}>
            <h3 className={styles.blogCategory}>
              <div>
                <span className={styles.blogCategory__title}>
                  Published:&nbsp;
                </span>
                {post.publishedAt}
              </div>
              <div>
                <span className={styles.blogCategory__title}>
                  Time to read:&nbsp;
                </span>
                <ReadTime {...post._rawBody} />
              </div>
              <div>
                <span className={styles.blogCategory__title}>
                  Category:&nbsp;
                </span>
                <Link to={"/blog/" + post.category.slug.current}>
                  {post.category.title}
                </Link>
              </div>
            </h3>
            <h1 className={styles.blogTitle}>{post.title}</h1>
          </div>
        </section>
        <section className={styles.blogContent}>
          <div>
            {post.author && (
              <Sidebar
                image={post.author.image ? post.author.image.asset.fluid : null}
                alt={post.author.image ? post.author.name : null}
              >
                <div>
                  <h3>Written by: {post.author.name}</h3>
                  <p>{post.author.bio}</p>
                </div>
              </Sidebar>
            )}
            <Categories
              postType="blog"
              usedCats={data?.allBlogCats}
              activeCat={post.category.slug.current}
            />
          </div>
          <BlockContent blocks={post._rawBody} />
        </section>
        {cta && (
          <PostCTA
            title={cta.ctaHeader}
            body={cta.ctaBody}
            links={cta.ctaButton}
            image={image.ctaImage}
          />
        )}
        <LatestBlocksSection
          blocksTypes="blogsResources"
          justBlogs
          category={post.category.slug.current}
          title={`Related Reading On ${post.category.title}`}
          slug={props.pageContext.slug}
        />
      </Layout>
    </>
  )
}

export default BlogTemplate
